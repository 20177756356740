<template>
  <div class="bg-white">


    <div class="relative isolate px-6 lg:px-8 ">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true">
        <div
          class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
      <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div class="hidden  sm:flex sm:justify-center ">

        </div>

        <div class="scroll-trigger-ready__worm-wrap" ref="myElement">
          <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center">Redes Para Todos</h1>
        </div>
        <div class="text-center">
          <p class="mt-6 text-lg leading-8 text-gray-600">Curso 100% Gratuito sobre redes de computadores aberto
            à Comunidade.</p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a href="https://forms.gle/dw1mr4Jvcsp7G3Q39"
              class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300">Participe
              Já</a>
            <router-link to="sobre-nos" class="text-sm font-semibold leading-6 text-gray-900 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300">Saiba Mais <span
                aria-hidden="true">→</span></router-link>
          </div>
        </div>
        <div class="text-center pt-14">
          <div class="text-2xl font-bold tracking-tight text-gray-700 sm:text-2xl text-center ">
            Apoio:
          </div>
          </div>
          <div class="flex flex-wrap align-center justify-center scroll-trigger-ready__worm-wrap ">
           <a href="https://vtp.ifsp.edu.br/"> <img class="sm:w-100 w-60 md:w-75 hover:-translate-y-1" src="../../src/images/ifsp.png" alt="IFSP LOGO"></a>
           <a href="https://www.flashnetbrasil.com.br/"> <img class="sm:w-100 w-60 md:w-75 hover:-translate-y-1" src="../../src/images/flash.png" alt="FLASH NET LOGO"></a>
          </div>
       
      </div>

      <div
        class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true">
        <div
          class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
    </div>
  </div>
</template>

<script>


export default {
  mounted() {
  },
}
</script>