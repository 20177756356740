<template>

<header class="absolute inset-x-0 top-0 z-50">
      <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div class="flex lg:flex-1">
          <router-link to="/home" class="-m-1.5 p-1.5">
            <span class="sr-only">Redes Para Todos</span>
            <img class="h-14 w-auto" src="../images/Logo.png" alt="" />
          </router-link>
        </div>
       
        <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-12">
          <router-link :to="item.href" v-for="item in navigation" :key="item.name"  class="text-sm font-semibold leading-6 text-gray-900">{{ item.name }}</router-link>
        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="https://forms.gle/dw1mr4Jvcsp7G3Q39" class="text-sm font-semibold leading-6 text-gray-900 hover:animate-pulse">Inscreva-Se <span aria-hidden="true">&rarr;</span></a>
        </div>
      </nav>
      <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-50" />
        <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div class="flex items-center justify-between">
            <a href="#" class="-m-1.5 p-1.5">
              <span class="sr-only">Your Company</span>
              <img class="h-14 w-auto" src="../images/Logo.png" alt="" />
            </a>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <router-link :to="item.href" v-for="item in navigation" :key="item.name"  class="text-sm font-semibold leading-6 text-gray-900 space-y-2 block">{{ item.name }}</router-link>
              </div>
              <div class="py-6">
                <a href="https://forms.gle/dw1mr4Jvcsp7G3Q39" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ">Inscreva-Se</a>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

const navigation = [
  { name: 'Início', href: '/home' },
  { name: 'Sobre Nós', href: '/sobre-nos' },
  { name: 'Fotos', href: '/fotos' },
]

const mobileMenuOpen = ref(false)
</script>