<template>
  <HeaderVue></HeaderVue>
  <router-view/>
  
  <AnfitrioesVue></AnfitrioesVue>
</template>

<style>

</style>
<script>
import HeaderVue from './components/HeaderVue.vue'


import AnfitrioesVue from './components/AnfitrioesVue.vue'
export default {
  name: 'App',
  components: {
    HeaderVue,
    AnfitrioesVue
  },
  mounted() {
  },
}
</script>